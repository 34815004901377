import { Datagrid, DateField, Filter, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext } from 'react-admin';
import { PERMISSIONS } from '../../constants';
import { getPlaceId } from '../../lib';

import { Typography } from '@mui/material';
import moment from 'moment';
import {
  Permission,
  ReportList
} from '../../components';
import { BetweenDatesInput } from '../../components/BetweenDatesInput';
import ListPagination from '../../components/ListPagination';

const RedemptionList = () => {
  const props = useListContext();
  let data = [];
  let summaryData: any[] = [];

  if (props.data) {
    const info = props.data[0];

    if (info) {
      data = info.data;
      summaryData = [info.summaryData];
    }
  }

  return (
    <>
      <Typography variant="subtitle1" align="center">Total</Typography>
      <Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData}>
        <NumberField
          source="totalValue"
          label="Total em Pontos"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="totalPartnerValue"
          label="Total em Dinheiro"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid>

      <Typography variant="subtitle1" align="center">Produtos resgatados</Typography>
      <Datagrid
        resource={props.resource}
        data={data}
        rowClick={false}
        bulkActionButtons={false}
      >
        <TextField source="productName" label="Produto" sortable={false} />
        <ReferenceField source="placeId" reference="places" label="Ponto de troca" textAlign="center" sortable={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="amount"
          label="Quantidade"
          textAlign="center"
          emptyText="0"
          defaultValue={0}
          sortable={false}
        />
        <NumberField
          source="value"
          label="Pontos"
          textAlign="center"
          emptyText="0"
          defaultValue={0}
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="totalValue"
          label="Total de Pontos"
          textAlign="center"
          emptyText="0"
          defaultValue={0}
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="partnerValue"
          label="Valor"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="totalPartnerValue"
          label="Valor Total"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid>
    </>
  );
}

export default props => {

  const ListFilters = () => (
    <Filter>
      <BetweenDatesInput alwaysOn />
      {
        !getPlaceId() &&
        <ReferenceInput
          source="placeId"
          reference="places"
          //@ts-ignore
          perPage={null}
          sort={{ field: "name", order: "ASC" }}
          allowEmpty
          alwaysOn
        >
          <SelectInput
            label="Pontos de troca"
            emptyText="Todos"
            optionText="name"
          />
        </ReferenceInput>
      }
    </Filter>
  );

  const FilterTablePrint = () => {
    const { filterValues } = useListContext();
    return (
      <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
        <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
        <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
        <ReferenceField source="placeId" label="Local de Troca" reference="places" emptyText="Todos" textAlign="center">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
      </Datagrid>
    );
  };

  return (
    <Permission permission={PERMISSIONS.REDEEMED_PRODUCTS_REPORT}>
      <ReportList
        {...props}
        title="Produtos Resgatados"
        resource="chains/chainId/reports/redeemed-products"
        filters={<ListFilters />}
        filter={getPlaceId() ? { placeId: getPlaceId() } : null}
        perPage={25}
        filterDefaultValues={{
          from: moment().startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }}
        sort={{ field: 'amount', order: 'DESC' }}
        pagination={<ListPagination />}
        bulkActionButtons={false}
        titleOnPrint="Relatório de Produtos resgatados"
        filterTablePrint={<FilterTablePrint />}
        landscape
        showPagination
      >
        <RedemptionList />
      </ReportList>
    </Permission>
  );
}
