import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  Datagrid,
  DateField,
  Filter,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import { Permission, ReportList } from "../../components";
import { BetweenDatesInput } from "../../components/BetweenDatesInput";
import { PartiallyHiddenCpfField } from "../../components/Fields";
import ListPagination from "../../components/ListPagination";
import { PERMISSIONS } from "../../constants";
import { getChainId } from "../../lib";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
}));

const DeviceFilter = () => {
  return (
    <Filter>
      <BetweenDatesInput alwaysOn />
      <TextInput alwaysOn source="search" label="Digite o CPF"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" style={{ marginTop: 8 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceInput
              label="Rede"
              source="chainId"
              reference="chains"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={9999}
              defaultValue={getChainId()}
              alwaysOn
            >
              <SelectInput source="name" />
            </ReferenceInput>
          )
        })
      }
    </Filter>
  );
};

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} />
      <DateField source="to" textAlign="center" label="Até" sortable={false} />
      <TextField source="search" label="Digite o CPF" textAlign="center" />
      {
        Permission({
          permission: PERMISSIONS.ADMIN,
          children: (
            <ReferenceField source="chainId" reference="chains" emptyText="Todos" textAlign="center" label="Rede" sortable={false} link={false}>
              <TextField source="name" />
            </ReferenceField>
          )
        })
      }
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
    </Datagrid>
  );
};

const DeviceList = () => {
  const classes = useStyles();
  return (
    <Datagrid
    >
      <TextField source="name" label="Nome" />
      <PartiallyHiddenCpfField source="cpf" label="CPF" textAlign="center" />
      <NumberField source="cnt_device" label="Dispositivos" textAlign="center" />
    </Datagrid>
  );
};

const ReportCustomerDevice = (props) => {
  return (
    <Permission permission={PERMISSIONS.DEVICE_CUSTOMER_REPORT}>
      <ReportList
        {...props}
        perPage={25}
        filters={<DeviceFilter />}
        filterTablePrint={<FilterTablePrint />}
        sort={{ field: "cnt_device", order: "DESC" }}
        basePath="report-device-customer"
        resource="chains/chainId/reports/device-customer"
        title="Dispositivos"
        filterDefaultValues={{
          from: moment().startOf("day").subtract(1, "month").toISOString(),
          to: moment().endOf("day").toISOString()
        }}
        pagination={<ListPagination />}
      >
        <DeviceList />
      </ReportList>
    </Permission>
  );
};

export default ReportCustomerDevice;