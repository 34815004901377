import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  TopToolbar,
  ShowButton,
  Toolbar,
  SaveButton,
  minValue,
} from "react-admin";
import { PriceInput } from '../../components';
import { FillinOrigin } from '../../models';

const transform = (data) => {
  const { place, fuel, ...rest } = data;
  return rest;
}

const FillinEdit = () => {

  const EditActions = () => (
    <TopToolbar>
      <ShowButton />
    </TopToolbar>
  );

  const CustomToolbar = () => (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit title="Editar Abastecimneto" transform={transform} redirect={"show"} actions={<EditActions />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <ReferenceInput reference="chains" source="chainId">
          <SelectInput
            label="Rede"
            optionText="name"
            sx={{ minWidth: 220 }}
            disabled
          />
        </ReferenceInput>
        <ReferenceInput reference="customers" source="customerId">
          <SelectInput
            label="Cliente"
            optionText="name"
            sx={{ minWidth: 220 }}
            disabled
          />
        </ReferenceInput>
        <NumberInput source="amount" label="Quantidade em litros" validate={[minValue(0.01)]} />
        <PriceInput source="value" label="Valor pago" locales="pt-BR" validate={[minValue(0.01)]} />
        <PriceInput source="pricePerLiter" label="Preço por litro" precision={3} locales="pt-BR" validate={[minValue(0.001)]} />
        <PriceInput source="oldPricePerLiter" label="Preço por litro original" precision={3} locales="pt-BR" validate={[minValue(0.001)]} />
        <NumberInput
          source="hoseNumber"
          label="Número do bico"
        />
        <SelectInput
          source="origin"
          label="Origem"
          choices={FillinOrigin.fillinOriginChoices}
          sx={{ minWidth: 220 }}
          isRequired
        />
        <ReferenceInput
          reference="fuels"
          source="fuelId"
          perPage={null}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput
            optionText="name"
            label="Combustível"
            sx={{ minWidth: 220 }}
            isRequired
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              reference="stations"
              source="placeId"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ chainId: formData.chainId }}
              perPage={null}
            >
              <SelectInput
                optionText="name"
                label="Posto"
                sx={{ minWidth: 220 }}
                isRequired
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              emptyText="Não identificado"
              source="attendantId"
              reference="attendants"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ chainId: formData.chainId }}
              perPage={null}
            >
              <SelectInput
                optionText="name"
                label="Frentista"
                sx={{ minWidth: 220 }}
                isRequired
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default FillinEdit;