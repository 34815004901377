import React from 'react'
import { List, Datagrid, TextField, Filter, SearchInput } from 'react-admin'
import Permission from '../../components/Permission'
import { PERMISSIONS } from '../../constants'
import ListPagination from '../../components/ListPagination'

const CustomerGroupFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
  </Filter>

);

const ListCustomerGroup = (props) =>
  <Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      bulkActionButtons={false}
      filters={<CustomerGroupFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      title="Grupos de clientes"
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Nome" />
        <TextField source="description" label="Descrição" />
      </Datagrid>
    </List>
  </Permission>;

export default ListCustomerGroup;
