import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ArrayField, BooleanField, BooleanInput, Datagrid, FormDataConsumer, FunctionField, NumberField, NumberInput, RaRecord, ReferenceArrayInput, ReferenceField, SelectArrayInput, SimpleShowLayout, TextField, required, useDataProvider, useGetList, usePermissions } from 'react-admin';
import { FuelDiscountsAccordion } from '../../../components/FuelAccordion';
import { PERMISSIONS, alterPriceTypes, alterPriceValueTypes } from '../../../constants';
import { getChainId } from '../../../lib';

const FuelDiscountsOverwriteAlert: React.FC<{ chain: any, stationIds: string[], selectsAllPlaces: boolean }> = React.memo(({ chain, stationIds, selectsAllPlaces }) => {
  const classes = useStyles();

  const commonFilters = { isDefault: true, isEnabled: true, chainId: getChainId() };
  const { data: discountsByStations } = useGetList('discounts',
    //@ts-ignore
    { page: 1, perPage: null },
    { field: 'createdAt', order: 'DESC' },
    selectsAllPlaces ? commonFilters : { placeIds: stationIds, ...commonFilters }
  );

  const formatDiscounts = useCallback(() => {
    return Object.values(
      Object.values(discountsByStations).reduce((acc, discount) => {
        discount.placeDiscounts?.forEach(placeDiscount => {
          acc[placeDiscount.placeId] = {
            id: placeDiscount.placeId,
            identify: discount.identify,
            fuelDiscounts: discount.fuelDiscounts,
          };
        });

        return acc;
      }, {})
    );
  }, [discountsByStations]);

  if (!discountsByStations || !Object.keys(discountsByStations).length) { return <></>; }

  return (
    <Alert severity="warning">
      <AlertTitle>Um ou mais postos selecionados possuem <strong>desconto fixo</strong> vinculado</AlertTitle>
      Se você continuar, os postos abaixo serão desvinculados de seus respectivos descontos e incorporados ao desconto em criação. Porém os descontos antigos ainda ficaram ativos em relação ao seus demais postos.

      {
        formatDiscounts().map((discount: any) => (
          <Accordion sx={{ marginTop: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ReferenceField source="id" record={discount as RaRecord} reference="stations" label="" link={false}>
                <TextField source="name" />
              </ReferenceField>
            </AccordionSummary>
            <AccordionDetails>
              <SimpleShowLayout record={{ ...discount }} className={classes.containerFuelDiscount}>
                <TextField source="identify" label="Código do desconto" />
                <ArrayField source="fuelDiscounts" label="Descontos">
                  <Datagrid bulkActionButtons={false}>
                    <ReferenceField source="fuelId" reference="fuels" label="Combustível" link={false} sortable={false}>
                      <TextField source="name" />
                    </ReferenceField>
                    {
                      chain && chain.isPriceIncreaseEnabled &&
                      <FunctionField
                        render={data => data && data.alterPriceType ? alterPriceTypes[data.alterPriceType] : 'Desconto'}
                        label="Tipo"
                        textAlign="center"
                      />
                    }
                    {
                      chain && chain.isPriceIncreaseEnabled &&
                      <FunctionField
                        render={data => data && data.alterPriceValueType ? alterPriceValueTypes[data.alterPriceValueType] : 'Centavos'}
                        label="Tipo de valor"
                        textAlign="center"
                      />
                    }
                    <FormDataConsumer label="Valor" textAlign="center" sortable={false}>
                      {({ record }: any) => (
                        record.alterPriceValueType === 'percentage' ?
                          <FunctionField
                            render={data => data && data.alterPriceValue ? `${data.alterPriceValue}%` : '0%'}
                          />
                          :
                          <NumberField
                            source="alterPriceValue"
                            emptyText="R$ 0,00"
                            options={{
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }}
                          />
                      )}
                    </FormDataConsumer>
                    <FormDataConsumer sortable={false}>
                      {({ record }: any) => (
                        record.uniqueDiscountValue ?
                          <></>
                          :
                          <NumberField
                            label="Volume"
                            textAlign="center"
                            source="amount"
                            emptyText="0"
                            options={{
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }}
                          />
                      )}
                    </FormDataConsumer>
                    <NumberField
                      source="points"
                      label="Pontos"
                      textAlign="center"
                      emptyText="0"
                      defaultValue={0}
                      options={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }}
                    />
                    <BooleanField source="uniqueDiscountValue" label="Valor do desconto único" />
                  </Datagrid>
                </ArrayField>
              </SimpleShowLayout>
            </AccordionDetails>
          </Accordion>
        ))
      }
    </Alert >
  );
});

const FormFuelDiscount: any = ({ fuels, chain, formData }: any) => {
  const chainId = getChainId();
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const [filterFuels, setfilterFuels] = useState<string[]>([]);

  useEffect(() => {
    if ((formData.stationIds && formData.stationIds.length > 0) || formData.selectsAllPlaces) {
      const filter = formData.selectsAllPlaces
        ? { chainId: getChainId() }
        : formData.stationIds.length > 0
          ? { placeIds: formData.stationIds }
          : {};

      dataProvider.getList('fuels', { filter, sort: { field: 'name', order: 'ASC' }, pagination: { page: 1, perPage: 9999 } })
        .then(({ data }) => {
          setfilterFuels(data.map((fuel) => fuel.id) as string[]);
        });
    }
  }, [formData.stationIds, formData.selectsAllPlaces]);

  return (
    <>
      {
        (!formData.chainId && (permissions && permissions.includes(PERMISSIONS.ADMIN))) &&
        <Typography>* Selecione uma rede na barra de ferramentas acima</Typography>
      }
      <Box style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        {
          !formData.selectsAllPlaces &&
          <ReferenceArrayInput
            style={{ minWidth: 260 }}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={null}
            source="stationIds"
            reference="stations"
            isRequired
            validate={[required()]}
          >
            <SelectArrayInput label="Postos" optionText="name" />
          </ReferenceArrayInput>
        }
        <BooleanInput
          source="selectsAllPlaces"
          label="Selecionar todos os postos"
        />
      </Box>

      {
        (formData.type === 'default' && ((formData.stationIds && formData.stationIds.length) || formData.selectsAllPlaces)) &&
        <FuelDiscountsOverwriteAlert stationIds={formData.stationIds} selectsAllPlaces={formData.selectsAllPlaces} chain={chain} />
      }

      {
        formData.type === 'special' &&
        <BooleanInput
          source="overlapsStandardDiscount"
          label="Sobrepor o desconto padrão"
          defaultValue={false}
        />
      }

      <Box display={{ xs: 'block', sm: 'flex' }}>
        <NumberInput
          source="usageLimit"
          label="Limite de uso"
          min={1}
        />
        <NumberInput
          style={{ marginLeft: 16, minWidth: 240 }}
          source="intervalInHours"
          label="Intervalo de uso (horas)"
          min={1}
        />
      </Box>

      {(chainId && fuels && (filterFuels.length > 0)) &&
        <FormDataConsumer>
          {({ formData: { fuelDiscounts } }) => {
            const arrayFuelDiscounts: ReactNode[] = []
            if (fuelDiscounts) {
              for (let index = 0; index < fuelDiscounts.length; index++) {
                if (filterFuels.includes(fuelDiscounts[index].id)) {
                  fuelDiscounts[index] = {
                    ...fuelDiscounts[index]
                  }
                  arrayFuelDiscounts.push(
                    //@ts-ignore
                    <FuelDiscountsAccordion
                      key={index}
                      name={fuelDiscounts[index].name}
                      source={`fuelDiscounts[${index}]`}
                      enableIncrease={chain && chain.isPriceIncreaseEnabled}
                    />
                  )
                } else {
                  fuelDiscounts[index] = {
                    ...fuelDiscounts[index],
                    discounts: [],
                  }
                }
              }
            }
            return arrayFuelDiscounts
          }}
        </FormDataConsumer>
      }
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  containerFuelDiscount: {
    padding: 0,
    paddingTop: '0px !important',
  }
}));

export default React.memo(FormFuelDiscount);