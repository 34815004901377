import { makeStyles } from '@mui/styles';
import {
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  minValue,
  required,
  useChoicesContext,
  useNotify
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { PriceInput, Wrapper } from '../../components';
import { CPFInput } from '../../components/Inputs';
import Permission from '../../components/Permission';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';

const useStyles = makeStyles({
  couponWrapper: {
    display: 'flex',
    width: '100%',
  },
});

const PointsCreateToolbar = () => {
  const notify = useNotify();
  const { reset } = useFormContext();

  return (
    <Toolbar>
      <SaveButton
        type="button"
        label="ra.action.save"
        variant="text"
        mutationOptions={{
          onSuccess: () => {
            reset();
            window.scrollTo(0, 0);
            notify('ra.notification.created', {
              type: 'info',
              messageArgs: { smart_count: 1 },
            });
          },
        }}
      />
    </Toolbar>
  );
};

export default props => {
  const classes = useStyles();

  const StationAttendantReference = (props) => {
    const { allChoices } = useChoicesContext();
    const ids = allChoices ? allChoices.map(a => a.attendantId) : [];
    return (
      <ReferenceInput
        source="attendantId"
        perPage={9999}
        filter={{ ids }}
        reference="attendants"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput
          label="Frentista"
          optionText="name" />
      </ReferenceInput>
    )
  }

  return (
    <Wrapper>
      <Permission permission={PERMISSIONS.ADD_POINTS}>
        <Create {...props} title="Adicionar Pontos" resource={`chains/${getChainId()}/addition-points`}>
          <SimpleForm toolbar={<PointsCreateToolbar />} defaultValues={{ placeId: getPlaceId() }}>
            <div className={classes.couponWrapper}>
              <NumberInput
                source="couponInvoice"
                label="Cupom Fiscal"
                validate={[required("Cupom Fiscal é obrigatório"), minValue(1)]}
                min={1}
                style={{ marginRight: '1rem' }}
              />
              <TextInput
                source="couponSerialNumber"
                label="Número de Série"
                validate={required("Número de Série é obrigatório")}
              />
            </div>
            <CPFInput source="customerCpf" label="CPF" isRequired />
            <TextInput source="description" label="Observação" multiline />
            {!getPlaceId() &&
              (<ReferenceInput
                source="placeId"
                filter={{ chainId: getChainId() }}
                perPage={9999}
                reference="stations"
                sort={{ field: 'name', order: 'ASC' }}
                validate={required("Posto é obrigatório")}
              >
                <SelectInput
                  label="Posto"
                  optionText="name" />
              </ReferenceInput>)}
            <FormDataConsumer>
              {({ formData }) => formData.placeId &&
                <ReferenceInput
                  source="attendantId"
                  perPage={9999}
                  filter={{ placeId: formData.placeId }}
                  reference="station-attendants"
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <StationAttendantReference />
                </ReferenceInput>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) => formData.placeId &&
                <ReferenceInput
                  source="fuelId"
                  filter={{ placeId: formData.placeId, hasFactor: true }}
                  reference="fuels"
                  sort={{ field: 'name', order: 'ASC' }}
                >
                  <SelectInput
                    validate={required("Combustível é obrigatório")}
                    label="Combustível"
                    optionText="name" />
                </ReferenceInput>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) => formData.fuelId &&
                <TextInput
                  source="fillinAmount"
                  label="Litros"
                  defaultValue="0"
                  format={(value) => {
                    if (!value || value === 0) {
                      return "0,00";
                    }
                    return `${Number(value).toFixed(2)}`;
                  }}
                  parse={(value) => {
                    if (!value || value === '') {
                      return 0;
                    }
                    const onlyNumbers = Number(value.replace(/[^\d]/g, ''));
                    return onlyNumbers / 100;
                  }}
                  validate={[required("Litros é obrigatório"), minValue(1)]}
                  style={{ width: 200, marginRight: 10 }}
                />
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData }) => formData.fuelId &&
                <PriceInput source="fillinValue" label="Valor" precision={2} validate={[required("Valor é obrigatório"), minValue(0.01)]} />
              }
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      </Permission>
    </Wrapper>
  )
}
