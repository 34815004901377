import {
  Create,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  Toolbar,
  minValue,
  required
} from 'react-admin';
import Permission from '../../../components/Permission';
import { useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../../../constants';
import { PointRule } from '../../../models';
import { useCallback } from 'react';


const validateInput = [required()];
const validateNumberInput = [required(), minValue(1)];

export default props => {
  const { state: params } = useLocation();

  const getInitialValues = useCallback(() => {
    const { ...restParams } = params as { [key: string]: string }
    return PointRule.createLimitInterval({ ...restParams });
  }, []);

  const CustomToolbar = () => {
    const enabled = params.id ? true : false;

    return (
      <Toolbar>
        <SaveButton alwaysEnable={enabled} />
      </Toolbar>
    );
  }

  return (
    <Permission permission={PERMISSIONS.POINT_RULES}>
      <Create {...props} title="Nova regra de Intervalo entre Abastecimentos">
        <SimpleForm defaultValues={getInitialValues()} toolbar={<CustomToolbar />}>
          <ReferenceArrayInput
            source="stationIds"
            reference="stations"
            perPage={undefined}
            sort={{ field: "name", order: "ASC" }}
            alwaysOn
          >
            <SelectArrayInput
              label="Posto"
              optionText="name"
              style={{ minWidth: 180 }}
            />
          </ReferenceArrayInput>
          <ReferenceInput
            source="fuelId"
            reference="fuels"
            sort={{ field: 'name', order: 'ASC' }}
            validate={validateInput}
          >
            <SelectInput label="Combustível" optionText="name" />
          </ReferenceInput>
          <NumberInput
            source='interval'
            label="Intervalo em Horas"
            validate={validateNumberInput}
          />
        </SimpleForm>
      </Create>
    </Permission>
  );
};
