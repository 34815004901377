import AddBoxIcon from '@mui/icons-material/AddBox';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BallotIcon from '@mui/icons-material/Ballot';
import CachedIcon from '@mui/icons-material/Cached';
import CampaignIcon from '@mui/icons-material/Campaign';
import RetailerIcon from '@mui/icons-material/CardTravel';
import CasinoIcon from '@mui/icons-material/Casino';
import CategoryIcon from '@mui/icons-material/Category';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ConfirmationIcon from '@mui/icons-material/ConfirmationNumber';
import Contacts from '@mui/icons-material/Contacts';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteIcon from '@mui/icons-material/Delete';
import Feed from '@mui/icons-material/Feed';
import Group from '@mui/icons-material/Group';
import GroupWork from '@mui/icons-material/GroupWork';
import HighLightIcon from '@mui/icons-material/Highlight';
import Label from '@mui/icons-material/Label';
import ListIcon from '@mui/icons-material/List';
import LocalAtm from '@mui/icons-material/LocalAtm';
import { default as LocalGasStation, default as LocalGasStationIcon } from '@mui/icons-material/LocalGasStation';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import Message from '@mui/icons-material/Message';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Newspaper from '@mui/icons-material/Newspaper';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserIcon from '@mui/icons-material/People';
import PermIdentity from '@mui/icons-material/PermIdentity';
import ImportIcon from '@mui/icons-material/Publish';
import Redeem from '@mui/icons-material/Redeem';
import ReportProblem from '@mui/icons-material/ReportProblem';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import SmsIcon from '@mui/icons-material/Sms';
import StarsIcon from '@mui/icons-material/Stars';
import Store from '@mui/icons-material/Store';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import { useEffect, useState } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink
} from 'react-admin';
import { CheckChainUsesModule, ModuleKey } from '../../components';
import Permission from '../../components/Permission';
import Permissions from '../../components/Permissions';
import { PERMISSIONS } from '../../constants';
import { getPlaceId } from '../../lib';
import SubMenu from './SubMenu';

import { Badge, BadgeProps, styled } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import { useStore } from '../../hooks/useStore';
import { HourglassBottom } from '@mui/icons-material';

const Menu = ({ dense = false }) => {
  const [state, setState] = useState({
    menuProducts: false,
    menuReports: false,
    menuRegister: false,
    menuContact: false,
    menuDeposit: false,
    menuSecurity: false,
    menuMarketing: false,
    menuPayments: false,
    menuPoints: false,
    menuDiscount: false,
    menuAlert: false,
    menuBilling: false,
    menuInformation: false,
    menuPointRules: false,
    menuReportsFillins: false,
    menuReportsRatings: false,
    menuReportsCustomers: false,
    menuReportsCoupons: false,
    menuReportsDiscount: false,
    menuReportsPoints: false,
    menuReportsProducts: false,
    menuReportsDrawings: false,
    menuReportsFinances: false,
    menuReportsAdmin: false,
    menuReportsPartners: false,
    menuReportsMarketing: false,
    menuReportsStations: false,
    menuLgpd: false,
  });

  const handleToggle = (menu) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  const [user, setUser] = useState<{ chainUseDrawing?: boolean | null }>();
  const [unreadTotalSupportTicket] = useStore('unreadTotalSupportTicket');
  const [unreadTotalFraud] = useStore('unreadTotalFraud');

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    setUser(JSON.parse(userJson || ''));
  }, []);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <div>
      <DashboardMenuItem />
      {getPlaceId() &&
        <>
          <Permission permission={PERMISSIONS.REFUND_COUPON}>
            <MenuItemLink
              to="/refund-coupon"
              primaryText="Estornar Cupom"
              leftIcon={<CachedIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.VALIDATE_COUPON}>
            <MenuItemLink
              to="/coupons"
              primaryText="Validar Cupom"
              leftIcon={<ConfirmationIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.LIST_PAYMENTS}>
            <MenuItemLink
              to="/payments"
              primaryText="Pagamentos"
              leftIcon={<AttachMoneyIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.BILLING}>
            <SubMenu
              handleToggle={() => handleToggle('menuBilling')}
              isOpen={state.menuBilling}
              name="Faturamento"
              icon={<MonetizationOnIcon />}
              dense={dense}
            >
              <MenuItemLink
                to="/billings"
                primaryText="Faturas"
                leftIcon={<ListIcon />}
              />
            </SubMenu>
          </Permission>
        </>
      }
      <Permissions permissions={[
        PERMISSIONS.CHAIN,
        PERMISSIONS.RETAILER,
        PERMISSIONS.EXTERNAL_PARTNER,
        PERMISSIONS.PERMISSION_GROUP,
        PERMISSIONS.USER_PERMISSION,
        PERMISSIONS.PARENT_FUEL,
        PERMISSIONS.FUEL,
        PERMISSIONS.DRAWING_RULE,
        PERMISSIONS.SERVICE,
        PERMISSIONS.LIST_CUSTOMER,
        PERMISSIONS.CREATE_USER,
        PERMISSIONS.CREATE_CUSTOMER,
        PERMISSIONS.UPDATE_CUSTOMER,
        PERMISSIONS.CREATE_PARTNER,
        PERMISSIONS.CUSTOMER_GROUPS,
        PERMISSIONS.CREATE_PROFILE,
        PERMISSIONS.STATION_GROUPS,
        PERMISSIONS.LIST_PROMOTER,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuRegister')}
          isOpen={state.menuRegister}
          name="Cadastro"
          icon={<GroupWork />}
          dense={dense}
        >
          <Permission permission={PERMISSIONS.CHAIN}>
            <MenuItemLink
              to="/chains"
              primaryText="Rede"
              leftIcon={<GroupWork />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.RETAILER}>
            <MenuItemLink
              to="/retailers"
              primaryText="Revendas"
              leftIcon={<RetailerIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.EXTERNAL_PARTNER}>
            <MenuItemLink
              to="/external-partners"
              primaryText="Parceiros Externos"
              leftIcon={<GroupWork />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.ADMIN_PROFILE}>
            <MenuItemLink
              to="/system-users"
              primaryText="Usuários do sistema"
              leftIcon={<UserIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_USER}>
            <MenuItemLink
              to="/employees"
              primaryText="Usuários da rede"
              leftIcon={<UserIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_LOCAL_USER}>
            <MenuItemLink
              to="/_employees"
              primaryText="Usuários de locais"
              leftIcon={<Group />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.MANAGE_ATTENDANT_USER}>
            <MenuItemLink
              to="/attendant-employees"
              primaryText="Usuários de frentistas"
              leftIcon={<Group />}
            />
          </Permission>
          <CheckChainUsesModule module={ModuleKey.PROMOTER}>
            <Permission permission={PERMISSIONS.LIST_PROMOTER}>
              <MenuItemLink
                to="/promoters"
                primaryText="Promoters"
                leftIcon={<CampaignIcon />}
              />
            </Permission>
          </CheckChainUsesModule>
          <Permissions permissions={[PERMISSIONS.LIST_CUSTOMER, PERMISSIONS.UPDATE_CUSTOMER, PERMISSIONS.CREATE_CUSTOMER]}>
            <MenuItemLink
              to="/customers"
              primaryText="Clientes"
              leftIcon={<Contacts />}
            />
          </Permissions>
          <Permission permission={PERMISSIONS.CUSTOMER_GROUPS}>
            <MenuItemLink
              to="/chains/chainId/customer-groups"
              primaryText="Grupos de clientes"
              leftIcon={<GroupWork />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_PARTNER}>
            <MenuItemLink
              to="/chains/chainId/partners"
              primaryText="Parceiros"
              leftIcon={<Store />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.LIST_STATION}>
            <MenuItemLink
              to="/stations"
              primaryText="Postos de combustível"
              leftIcon={<LocalGasStation />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.STATION_GROUPS}>
            <MenuItemLink
              to="/chains/chainId/station-groups"
              primaryText="Grupos de Postos"
              leftIcon={<LocalGasStation />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.LIST_ATTENDANT}>
            <MenuItemLink
              to="/attendants"
              primaryText="Frentistas"
              leftIcon={<AssignmentInd />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_PROFILE}>
            <MenuItemLink
              to="/profiles"
              primaryText="Perfis"
              leftIcon={<SupervisedUserCircleIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.PERMISSION_GROUP}>
            <MenuItemLink
              to="/permission-groups"
              primaryText="Grupos de Permissões"
              leftIcon={<LockOpenIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.USER_PERMISSION}>
            <MenuItemLink
              to="/user-permissions"
              primaryText="Permissões"
              leftIcon={<LockOpenIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.PARENT_FUEL}>
            <MenuItemLink
              to="/parent-fuels"
              primaryText="Combustíveis pai"
              leftIcon={<LocalGasStation />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.FUEL}>
            <MenuItemLink
              to="/fuels"
              primaryText="Combustíveis"
              leftIcon={<LocalGasStation />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.TAG}>
            <MenuItemLink
              to="/chains/chainId/tag"
              primaryText="Etiquetas"
              leftIcon={<Label />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.DRAWING_RULE}>
            <MenuItemLink
              to="/drawing-rules"
              primaryText="Regras de Sorteio"
              leftIcon={<CasinoIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.SERVICE}>
            <MenuItemLink
              to="/services"
              primaryText="Serviços"
              leftIcon={<RoomServiceIcon />}
            />
          </Permission>
        </SubMenu>
      </Permissions>

      <Permissions permissions={[
        PERMISSIONS.CREATE_PRODUCT,
        PERMISSIONS.UPDATE_FEATURED_PRODUCTS]}>
        <SubMenu
          handleToggle={() => handleToggle('menuProducts')}
          isOpen={state.menuProducts}
          name="Produtos"
          dense={dense}
          icon={<Redeem />}
        >
          <Permission permission={PERMISSIONS.CREATE_PRODUCT}>
            <MenuItemLink
              to="/chains/chainId/products"
              primaryText="Produtos"
              leftIcon={<Redeem />}
            />
          </Permission>
          <CheckChainUsesModule module={ModuleKey.GIFT}>
            <Permission permission={PERMISSIONS.GIFT}>
              <MenuItemLink
                to="/gifts"
                primaryText="Brinde"
                leftIcon={<Redeem />}
              />
            </Permission>
          </CheckChainUsesModule>
          <Permission permission={PERMISSIONS.FILLIN_CASHBACK}>
            <MenuItemLink
              to="/chains/chainId/fillin-cashback"
              primaryText="Cashback de abastecimento"
              leftIcon={<LocalAtm />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_PRODUCT_CATEGORIES}>
            <MenuItemLink
              to="/chains/chainId/product-categories"
              primaryText="Categorias"
              leftIcon={<CategoryIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.UPDATE_FEATURED_PRODUCTS}>
            <MenuItemLink
              to="/chains/chainId/featured-products"
              primaryText="Produtos Destaque"
              leftIcon={<HighLightIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CREATE_PRODUCT}>
            <MenuItemLink
              to="/chains/chainId/sale-products"
              primaryText="Produtos de Venda"
              leftIcon={<ShoppingCart />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[
        PERMISSIONS.FUEL_DISCOUNTS]}>
        <MenuItemLink
          to="/discounts"
          primaryText="Descontos"
          dense={dense}
          leftIcon={<LoyaltyIcon />}
        />
      </Permissions>
      <Permissions permissions={[
        PERMISSIONS.PRODUCT_ENTRY,
        PERMISSIONS.PRODUCT_WRITEOFF,
        PERMISSIONS.STOCK_ADJUSTMENT,
        PERMISSIONS.STOCK_TRANSFER]}>
        <SubMenu
          handleToggle={() => handleToggle('menuDeposit')}
          isOpen={state.menuDeposit}
          name="Estoque"
          dense={dense}
          icon={<BallotIcon />}
        >
          <Permission permission={PERMISSIONS.PRODUCT_ENTRY}>
            <MenuItemLink
              to="/warehouse/product-entry"
              primaryText="Entrada de produto"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.PRODUCT_WRITEOFF}>
            <MenuItemLink
              to="/warehouse/product-write-off"
              primaryText="Baixa de produto"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.STOCK_ADJUSTMENT}>
            <MenuItemLink
              to="/warehouse/adjustment"
              primaryText="Ajuste de estoque"
              leftIcon={<TrackChangesIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.STOCK_TRANSFER}>
            <MenuItemLink
              to="/warehouse/product-transfer"
              primaryText="Transferência"
              leftIcon={<CompareArrowsIcon />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[PERMISSIONS.TERMS_OF_USE, PERMISSIONS.CUSTOMERS_DELETED]}>
        <SubMenu
          handleToggle={() => handleToggle('menuLgpd')}
          isOpen={state.menuLgpd}
          name="LGPD"
          dense={dense}
          icon={<AssignmentIcon />}
        >
          <Permission permission={PERMISSIONS.TERMS_OF_USE}>
            <MenuItemLink
              to="/terms-use"
              primaryText="Termos de Uso"
              leftIcon={<ContentPasteIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.CUSTOMERS_DELETED}>
            <MenuItemLink
              to="/customers-deleted"
              primaryText="Clientes excluídos"
              leftIcon={<DeleteIcon />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions
        permissions={[
          PERMISSIONS.SPECIAL_RULES,
          PERMISSIONS.MARKETING_CAMPAIGNS,
          PERMISSIONS.UNIFIED_SEND,
          PERMISSIONS.DISPATCH_MESSAGE,
          PERMISSIONS.ADVERTISEMENT,
        ]}
      >
        <SubMenu
          handleToggle={() => handleToggle('menuMarketing')}
          isOpen={state.menuMarketing}
          name="Marketing"
          dense={dense}
          icon={<SendIcon />}
        >
          <Permissions permissions={[PERMISSIONS.ADVERTISEMENT]}>
            <CheckChainUsesModule module={ModuleKey.ADVERTISEMENT}>
              <MenuItemLink
                to="/advertisements"
                primaryText="Anúncios"
                leftIcon={<CampaignIcon />}
              />
            </CheckChainUsesModule>
          </Permissions>
          <Permission permission={PERMISSIONS.MARKETING_CAMPAIGNS}>
            <MenuItemLink
              to="/chains/chainId/chain-marketing-campaigns"
              primaryText="Campanhas"
              leftIcon={<NotificationsIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.UNIFIED_SEND}>
            <MenuItemLink
              to="/chains/chainId/unified-send"
              primaryText="Envio Único"
              leftIcon={<Message />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.BULK_SEND}>
            <MenuItemLink
              to="/bulk-sends"
              primaryText="Envio em Massa"
              leftIcon={<Message />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.DISPATCH_MESSAGE}>
            <MenuItemLink
              to="/chains/chainId/dispatch-messages"
              primaryText="Envios"
              leftIcon={<SendIcon />}
            />
          </Permission>
          {
            (user && user?.chainUseDrawing === true) &&
            <Permission permission={PERMISSIONS.DRAWING}>
              <MenuItemLink
                to="/chains/chainId/drawings"
                primaryText="Sorteios"
                leftIcon={<CasinoIcon />}
              />
            </Permission>
          }
        </SubMenu>
      </Permissions>
      <Permissions permissions={[PERMISSIONS.LIST_NEWS]}>
        <SubMenu
          name="Notícias"
          handleToggle={() => handleToggle('menuInformation')}
          isOpen={state.menuInformation}
          dense={dense}
          icon={<Newspaper />}
        >
          <Permission permission={PERMISSIONS.LIST_NEWS}>
            <MenuItemLink
              to="/news"
              primaryText="Novidades"
              leftIcon={<Feed />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[PERMISSIONS.SEND_NOTIFICATION, PERMISSIONS.LIST_MESSAGES, PERMISSIONS.LIST_RATINGS]}>
        <SubMenu
          handleToggle={() => handleToggle('menuContact')}
          isOpen={state.menuContact}
          name="Contato"
          dense={dense}
          icon={
            <Badge variant="dot" color="primary" invisible={!(unreadTotalSupportTicket > 0)}>
              <Message />
            </Badge>
          }
        >
          {/* <Permission permission={PERMISSIONS.SEND_NOTIFICATION}>
            <MenuItemLink
              to="/chains/chainId/notifications"
              primaryText="Notificações"
              leftIcon={<NotificationsIcon />}
            />
          </Permission> */}
          <Permission permission={PERMISSIONS.LIST_MESSAGES}>
            <MenuItemLink
              to="/chains/chainId/support-tickets"
              primaryText="Mensagens"
              leftIcon={
                < StyledBadge badgeContent={unreadTotalSupportTicket || 0} color="primary">
                  <Message />
                </StyledBadge>
              }
            />
          </Permission>
          {/* this feature is temporarily disabled */}
          {false && <Permission permission={PERMISSIONS.SEND_SMS}>
            <MenuItemLink
              to="/sms"
              primaryText="SMS"
              leftIcon={<SmsIcon />}
            />
          </Permission>
          }
          <Permission permission={PERMISSIONS.LIST_RATINGS}>
            <MenuItemLink
              to="/ratings"
              primaryText="Avaliações"
              leftIcon={<StarsIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.RATING_POLL_ITEMS}>
            <MenuItemLink
              to="/rating-poll-items"
              primaryText="Enquetes de avaliação"
              leftIcon={<ReviewsIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.LIST_RATINGS}>
            <MenuItemLink
              to="/stores-rating"
              primaryText="Avaliação nas Lojas"
              leftIcon={<StarsIcon />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[
        PERMISSIONS.POINTS_REPORT,
        PERMISSIONS.DETAILED_POINTS_REPORT,
        PERMISSIONS.ADDED_POINTS_REPORT,
        PERMISSIONS.ADVERTISEMENT_VIEWS_REPORT,
        PERMISSIONS.NON_VALIDATED_COUPONS_REPORT,
        PERMISSIONS.EXPIRED_POINTS_REPORT,
        PERMISSIONS.REDEEMED_PRODUCTS_REPORT,
        PERMISSIONS.COUPONS_REPORT,
        PERMISSIONS.STOCK_STATEMENT_REPORT,
        PERMISSIONS.STOCK_CHANGES_REPORT,
        PERMISSIONS.NOTIFICATIONS_REPORT,
        PERMISSIONS.MINIMUM_STOCK_REPORT,
        PERMISSIONS.PURCHASE_SUGGESTION_REPORT,
        PERMISSIONS.TOP_CUSTOMERS_REPORT,
        PERMISSIONS.REAL_PRICE_REPORT,
        PERMISSIONS.SOLD_PRODUCTS_REPORT,
        PERMISSIONS.FILLINS_REPORT,
        PERMISSIONS.BLOCKED_CUSTOMERS_REPORT,
        PERMISSIONS.FILLIN_BY_FUEL_REPORT,
        PERMISSIONS.ATTENDANT_RATING_REPORT,
        PERMISSIONS.FILLIN_BY_STATION_REPORT,
        PERMISSIONS.PAYMENT_BY_PIX_REPORT,
        PERMISSIONS.DRAWING_REPORT,
        PERMISSIONS.RECURRING_CUSTOMERS_REPORT,
        PERMISSIONS.PARTNER_VALUES_REPORT,
        PERMISSIONS.POINTS_BALANCE_REPORT,
        PERMISSIONS.PRODUCTS_REPORT,
        PERMISSIONS.BILLINGS_REPORT,
        PERMISSIONS.DEFAULT_POINTS_REPORT,
        PERMISSIONS.INACTIVE_CUSTOMERS_REPORT,
        PERMISSIONS.PENDING_TRANSACTION_REPORT,
        PERMISSIONS.MONTHLY_EVOLUTION_REPORT,
        PERMISSIONS.REDEMPTION_X_POINTS,
        PERMISSIONS.CUSTOMERS_WITHOUT_DRAWING_REPORT,
        PERMISSIONS.FAVORITE_STATION_REPORT,
        PERMISSIONS.REFUNDED_COUPONS_REPORT,
        PERMISSIONS.REDEEMED_COUPONS_REPORT,
        PERMISSIONS.CONCENTRATOR_ERROR_REPORT,
        PERMISSIONS.SOCKET_ERROR_REPORT,
        PERMISSIONS.CURRENT_FILLINS_REPORT,
        PERMISSIONS.EXTERNAL_PARTNERS_REPORT,
        PERMISSIONS.RETAILERS_REPORT,
        PERMISSIONS.APPROVED_TRANSACTIONS_REPORT,
        PERMISSIONS.ATTENDANT_REPORT,
        PERMISSIONS.CLIENTS_GENERATED_POINTS_REPORT,
        PERMISSIONS.DEVICE_CUSTOMER_REPORT,
        PERMISSIONS.DISCOUNT_REPORT,
        PERMISSIONS.FILLIN_BY_ATTENDANT_REPORT,
        PERMISSIONS.FILLIN_BY_CUSTOMER_REPORT,
        PERMISSIONS.FIRST_FILLIN_REPORT,
        PERMISSIONS.NEW_CUSTOMERS_REPORT,
        PERMISSIONS.PARTNERS_REPORT,
        PERMISSIONS.REFERED_CUSTOMERS_REPORT,
        PERMISSIONS.REGISTERED_DISCOUNTS_REPORT,
        PERMISSIONS.STATION_RATING_REPORT,
        PERMISSIONS.STATION_REPORT,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuReports')}
          isOpen={state.menuReports}
          name="Relatórios"
          dense={dense}
          icon={<AssessmentIcon />}
        >
          <Permissions permissions={[
            // Relatorios de admin
            PERMISSIONS.CONCENTRATOR_ERROR_REPORT,
            PERMISSIONS.SOCKET_ERROR_REPORT,
            PERMISSIONS.CURRENT_FILLINS_REPORT,
            PERMISSIONS.EXTERNAL_PARTNERS_REPORT,
            PERMISSIONS.RETAILERS_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsAdmin')}
                isOpen={state.menuReportsAdmin}
                name="Admin"
                dense={dense}
                icon={<PermIdentity />}
              >
                <Permission permission={PERMISSIONS.CONCENTRATOR_ERROR_REPORT}>
                  <MenuItemLink
                    to="/report-concentrator-error"
                    primaryText="Hora do Concentrador"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.SOCKET_ERROR_REPORT}>
                  <MenuItemLink
                    to="/report-socket-error"
                    primaryText="Erros"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.CURRENT_FILLINS_REPORT}>
                  <MenuItemLink
                    to="/report-current-fillins"
                    primaryText="Em Andamento"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.EXTERNAL_PARTNERS_REPORT}>
                  <MenuItemLink
                    to="/report-external-partners"
                    primaryText="Parceiros Externos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.RETAILERS_REPORT}>
                  <MenuItemLink
                    to="/report-retailers"
                    primaryText="Revendas"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Abastecimentos
            PERMISSIONS.FILLINS_REPORT,
            PERMISSIONS.FILLIN_BY_CUSTOMER_REPORT,
            PERMISSIONS.FILLIN_BY_FUEL_REPORT,
            PERMISSIONS.FILLIN_BY_ATTENDANT_REPORT,
            PERMISSIONS.FILLIN_BY_STATION_REPORT,
            PERMISSIONS.FIRST_FILLIN_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsFillins')}
                isOpen={state.menuReportsFillins}
                name="Abastecimentos"
                dense={dense}
                icon={<LocalGasStation />}
              >
                <Permission permission={PERMISSIONS.FILLINS_REPORT}>
                  <MenuItemLink
                    to="/report-fillins"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FILLIN_BY_CUSTOMER_REPORT}>
                  <MenuItemLink
                    to="/report-fillin-by-customer"
                    primaryText="Por Cliente"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FILLIN_BY_FUEL_REPORT}>
                  <MenuItemLink
                    to="/report-fillin-by-fuel"
                    primaryText="Por Combustível"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FILLIN_BY_ATTENDANT_REPORT}>
                  <MenuItemLink
                    to="/report-fillin-by-attendant"
                    primaryText="Por Frentista"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FILLIN_BY_STATION_REPORT}>
                  <MenuItemLink
                    to="/report-fillin-by-station"
                    primaryText="Por Posto"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FIRST_FILLIN_REPORT}>
                  <MenuItemLink
                    to="/report-first-fillin"
                    primaryText="Primeiro Abastecimento"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          {/* <Permission permission={PERMISSIONS.PAYMENT_BY_PIX_REPORT}>
            <MenuItemLink
              to="/report-registration-customers"
              primaryText="Cadastro de Clientes"
              leftIcon={<AssessmentIcon />}
            />
          </Permission> */}
          <Permissions
            permissions={[
              // Relatorios de Avaliações
              PERMISSIONS.STATION_RATING_REPORT,
              PERMISSIONS.RATING_POLL_ITEMS_REPORT,
            ]}
          >
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }} >
              <SubMenu
                handleToggle={() => handleToggle('menuReportsRatings')}
                isOpen={state.menuReportsRatings}
                name="Avaliações"
                dense={dense}
                icon={<StarsIcon />}

              >
                <Permission permission={PERMISSIONS.STATION_RATING_REPORT}>
                  <MenuItemLink
                    to="/report-station-rating"
                    primaryText="Avaliação de Postos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.RATING_POLL_ITEMS_REPORT}>
                  <MenuItemLink
                    to="/report-rating-poll-group"
                    primaryText="Enquetes"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>
          <Permissions permissions={[
            // Relatorios de Clientes
            PERMISSIONS.BLOCKED_CUSTOMERS_REPORT,
            PERMISSIONS.INACTIVE_CUSTOMERS_REPORT,
            PERMISSIONS.CLIENTS_GENERATED_POINTS_REPORT,
            PERMISSIONS.RECURRING_CUSTOMERS_REPORT,
            PERMISSIONS.TOP_CUSTOMERS_REPORT,
            PERMISSIONS.NEW_CUSTOMERS_REPORT,
            PERMISSIONS.REDEMPTION_X_POINTS,
            PERMISSIONS.DEVICE_CUSTOMER_REPORT,
            PERMISSIONS.LIST_CUSTOMER,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsCustomers')}
                isOpen={state.menuReportsCustomers}
                name="Clientes"
                dense={dense}
                icon={<Contacts />}
              >
                <Permission permission={PERMISSIONS.LIST_CUSTOMER}>
                  <MenuItemLink
                    to="/report-customers"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.BLOCKED_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-blocked-customers"
                    primaryText="Bloqueados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.DEVICE_CUSTOMER_REPORT}>
                  <MenuItemLink
                    to="/report-device-customer"
                    primaryText="Dispositivos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.INACTIVE_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-inactive-customers"
                    primaryText="Inativos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REFERED_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-refered-customers"
                    primaryText="Indicações"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                {/* <Permission permission={PERMISSIONS.CLIENTS_GENERATED_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-clients-generated-points"
                    primaryText="Pontos gerados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission> */}
                <Permission permission={PERMISSIONS.RECURRING_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-recurring-customers"
                    primaryText="Recorrentes"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.NEW_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-new-customers"
                    primaryText="Novos Clientes"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.TOP_CUSTOMERS_REPORT}>
                  <MenuItemLink
                    to="/report-top-customers"
                    primaryText="Top Clientes"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REDEMPTION_X_POINTS}>
                  <MenuItemLink
                    to="/report-redemption-x-points"
                    primaryText="Resgate X Pontos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Cupons
            PERMISSIONS.COUPONS_REPORT,
            PERMISSIONS.REFUNDED_COUPONS_REPORT,
            PERMISSIONS.REDEEMED_COUPONS_REPORT,
            PERMISSIONS.NON_VALIDATED_COUPONS_REPORT,
          ]} >
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsCoupons')}
                isOpen={state.menuReportsCoupons}
                name="Cupons"
                dense={dense}
                icon={<LoyaltyIcon />}
              >
                <Permission permission={PERMISSIONS.COUPONS_REPORT}>
                  <MenuItemLink
                    to="/report-coupons"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REFUNDED_COUPONS_REPORT}>
                  <MenuItemLink
                    to="/report-refunded-coupons"
                    primaryText="Estornados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REDEEMED_COUPONS_REPORT}>
                  <MenuItemLink
                    to="/report-redeemed-coupons"
                    primaryText="Resgatados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.NON_VALIDATED_COUPONS_REPORT}>
                  <MenuItemLink
                    to="/report-non-validated-coupons"
                    primaryText="Não validados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[PERMISSIONS.DISCOUNT_REPORT]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsDiscount')}
                isOpen={state.menuReportsDiscount}
                name="Descontos"
                dense={dense}
                icon={<LoyaltyIcon />}
              >
                <Permission permission={PERMISSIONS.DISCOUNT_REPORT}>
                  <MenuItemLink
                    to="/report-discount"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>

          </Permissions>

          <Permissions permissions={[
            // Relatorios de Finanças
            PERMISSIONS.REGISTERED_DISCOUNTS_REPORT,
            PERMISSIONS.BILLINGS_REPORT,
            PERMISSIONS.PAYMENT_BY_PIX_REPORT,
            PERMISSIONS.REAL_PRICE_REPORT,
            PERMISSIONS.MONTHLY_EVOLUTION_REPORT,
          ]} >
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsFinances')}
                isOpen={state.menuReportsFinances}
                name="Financeiro"
                dense={dense}
                icon={<LocalAtm />}
              >
                {/* <Permission permission={PERMISSIONS.REGISTERED_DISCOUNTS_REPORT}>
                  <MenuItemLink
                    to="/report-registered-discounts"
                    primaryText="Descontos Cadastrados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission> */}
                <Permission permission={PERMISSIONS.MONTHLY_EVOLUTION_REPORT}>
                  <MenuItemLink
                    to="/report-monthly-evolution"
                    primaryText="Evolução mensal"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.PAYMENT_BY_PIX_REPORT}>
                  <MenuItemLink
                    to="/report-payments-by-pix"
                    primaryText="Pagamentos por Pix"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REAL_PRICE_REPORT}>
                  <MenuItemLink
                    to="/report-real-price"
                    primaryText="Preço real"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.APPROVED_TRANSACTIONS_REPORT}>
                  <MenuItemLink
                    to="/report-approved-transactions"
                    primaryText="Transações Aprovadas"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Marketing
            PERMISSIONS.NOTIFICATIONS_REPORT,
            PERMISSIONS.ADVERTISEMENT_VIEWS_REPORT,
          ]} >
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsMarketing')}
                isOpen={state.menuReportsMarketing}
                name="Marketing"
                dense={dense}
                icon={<SendIcon />}
              >
                <Permission permission={PERMISSIONS.NOTIFICATIONS_REPORT}>
                  <MenuItemLink
                    to="/report-notifications"
                    primaryText="Notificações Push"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <CheckChainUsesModule module={ModuleKey.ADVERTISEMENT}>
                  <Permission permission={PERMISSIONS.ADVERTISEMENT_VIEWS_REPORT}>
                    <CheckChainUsesModule module={ModuleKey.ADVERTISEMENT}>
                      <MenuItemLink
                        to="/report-advertisement-viwing"
                        primaryText="Visualização de Anúncios"
                        leftIcon={<AssessmentIcon />}
                      />
                    </CheckChainUsesModule>
                  </Permission>
                </CheckChainUsesModule>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Parceiros
            PERMISSIONS.PARTNERS_REPORT,
            PERMISSIONS.BILLINGS_REPORT,
            PERMISSIONS.PARTNER_VALUES_REPORT,
          ]} >
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsPartners')}
                isOpen={state.menuReportsPartners}
                name="Parceiros"
                dense={dense}
                icon={<Store />}
              >
                <Permission permission={PERMISSIONS.PARTNERS_REPORT}>
                  <MenuItemLink
                    to="/report-partners"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.BILLINGS_REPORT}>
                  <MenuItemLink
                    to="/report-billings"
                    primaryText="Faturas"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.PARTNER_VALUES_REPORT}>
                  <MenuItemLink
                    to="/report-partner-values"
                    primaryText="Valores por parceiros"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Pontos
            PERMISSIONS.POINTS_REPORT,
            PERMISSIONS.DETAILED_POINTS_REPORT,
            PERMISSIONS.ADDED_POINTS_REPORT,
            PERMISSIONS.EXPIRED_POINTS_REPORT,
            PERMISSIONS.POINTS_BALANCE_REPORT,
            PERMISSIONS.PENDING_TRANSACTION_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsPoints')}
                isOpen={state.menuReportsPoints}
                name="Pontos"
                dense={dense}
                icon={<BallotIcon />}
              >
                {/* <Permission permission={PERMISSIONS.POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-points"
                    primaryText="Pontos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission> */}
                <Permission permission={PERMISSIONS.DEFAULT_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-default-points"
                    primaryText="Pontuação Padrão"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.DETAILED_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-detailed-points"
                    primaryText="Detalhados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.ADDED_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-added-points"
                    primaryText="Distribuídos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.EXPIRED_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-expiration-by-customer"
                    primaryText="Expirados por cliente"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.EXPIRED_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-expiration-by-date"
                    primaryText="Expirados por dia"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.LIFE_POINTS_REPORT}>
                  <MenuItemLink
                    to="/report-life-points"
                    primaryText="Vitalícios"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.PENDING_TRANSACTION_REPORT} >
                  <MenuItemLink
                    to="/report-pending-transaction"
                    primaryText="Pendentes"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.POINTS_BALANCE_REPORT}>
                  <MenuItemLink
                    to="/report-points-balance"
                    primaryText="Saldo de Pontos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Posto
            PERMISSIONS.ATTENDANT_RATING_REPORT,
            PERMISSIONS.ATTENDANT_REPORT,
            PERMISSIONS.STATION_REPORT,
            PERMISSIONS.FAVORITE_STATION_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsStations')}
                isOpen={state.menuReportsStations}
                name="Postos"
                dense={dense}
                icon={<LocalGasStation />}
              >
                <Permission permission={PERMISSIONS.STATION_REPORT}>
                  <MenuItemLink
                    to="/report-station"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.FAVORITE_STATION_REPORT}>
                  <MenuItemLink
                    to="/report-favorite-station"
                    primaryText="Favoritos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.ATTENDANT_REPORT}>
                  <MenuItemLink
                    to="/report-attendant"
                    primaryText="Frentistas"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            // Relatorios de Produtos
            PERMISSIONS.PRODUCTS_REPORT,
            PERMISSIONS.REDEEMED_PRODUCTS_REPORT,
            PERMISSIONS.SOLD_PRODUCTS_REPORT,
            PERMISSIONS.STOCK_CHANGES_REPORT,
            PERMISSIONS.MINIMUM_STOCK_REPORT,
            PERMISSIONS.STOCK_STATEMENT_REPORT,
            PERMISSIONS.PURCHASE_SUGGESTION_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsProducts')}
                isOpen={state.menuReportsProducts}
                name="Produtos"
                dense={dense}
                icon={<Redeem />}
              >
                <Permission permission={PERMISSIONS.PRODUCTS_REPORT}>
                  <MenuItemLink
                    to="/report-products"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.MINIMUM_STOCK_REPORT}>
                  <MenuItemLink
                    to="/report-minimum-stock"
                    primaryText="Estoque mínimo"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.REDEEMED_PRODUCTS_REPORT}>
                  <MenuItemLink
                    to="/report-redemption"
                    primaryText="Resgatados"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.SOLD_PRODUCTS_REPORT}>
                  <MenuItemLink
                    to="/report-sold-products"
                    primaryText="Vendidos"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.STOCK_CHANGES_REPORT}>
                  <MenuItemLink
                    to="/report-products-stock"
                    primaryText="Movimentação de estoque"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.STOCK_CHANGES_REPORT}>
                  <MenuItemLink
                    to="/report-synthetic-products-stock"
                    primaryText="Mov. de estoque(Resumo)"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.STOCK_STATEMENT_REPORT}>
                  <MenuItemLink
                    to="/report-inventory"
                    primaryText="Posição de estoque"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.PURCHASE_SUGGESTION_REPORT}>
                  <MenuItemLink
                    to="/report-purchase-suggestion"
                    primaryText="Sugestão de compra"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

          <Permissions permissions={[
            //Relatorios de sorteios
            PERMISSIONS.DRAWING_REPORT,
            PERMISSIONS.CUSTOMERS_WITHOUT_DRAWING_REPORT,
          ]}>
            <MenuItem style={{ display: 'list-item', paddingBlock: 4 }}>
              <SubMenu
                handleToggle={() => handleToggle('menuReportsDrawings')}
                isOpen={state.menuReportsDrawings}
                name="Sorteios"
                dense={dense}
                icon={<CasinoIcon />}
              >
                <Permission permission={PERMISSIONS.DRAWING_REPORT}>
                  <MenuItemLink
                    to="/report-drawing"
                    primaryText="Geral"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
                <Permission permission={PERMISSIONS.CUSTOMERS_WITHOUT_DRAWING_REPORT}>
                  <MenuItemLink
                    to="/report-customers-without-drawing"
                    primaryText="Clientes sem Número"
                    leftIcon={<AssessmentIcon />}
                  />
                </Permission>
              </SubMenu>
            </MenuItem>
          </Permissions>

        </SubMenu >
      </Permissions>
      <Permissions permissions={[PERMISSIONS.BILLING_ALERT]}>
        <SubMenu
          handleToggle={() => handleToggle('menuAlert')}
          isOpen={state.menuAlert}
          name="Alertas"
          dense={dense}
          icon={<ReportProblem />}
        >
          <Permissions permissions={[PERMISSIONS.BILLING_ALERT]}>
            <MenuItemLink
              to="/alert-billing"
              primaryText="Faturamento"
              leftIcon={<LocalAtm />}
            />
          </Permissions>
          <Permissions permissions={[PERMISSIONS.EXPIRATION_ALERT]}>
            <MenuItemLink
              to="/alert-expiration"
              primaryText="Expiração"
              leftIcon={<HourglassBottom />}
            />
          </Permissions>
        </SubMenu>
      </Permissions>
      <Permissions permissions={[
        PERMISSIONS.POTENCIAL_FRAUD_LIST,
        PERMISSIONS.POTENCIAL_FRAUD_RULE,
        PERMISSIONS.LIST_CUSTOMERS_IGNORED,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuSecurity')}
          isOpen={state.menuSecurity}
          name="Segurança"
          dense={dense}
          icon={
            <Badge variant="dot" color="primary" invisible={!(unreadTotalFraud > 0)}>
              <SecurityIcon />
            </Badge>
          }
        >
          <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_LIST}>
            <MenuItemLink
              to="/chains/chainId/potencial-frauds"
              primaryText="Análise de Segurança"
              leftIcon={
                < StyledBadge badgeContent={unreadTotalFraud || 0} color="primary">
                  <SecurityIcon />
                </StyledBadge>
              }
            />
          </Permission>
          <Permission permission={PERMISSIONS.POTENCIAL_FRAUD_RULE}>
            <MenuItemLink
              to="/security-rules-edit"
              primaryText="Regras"
              leftIcon={<SecurityIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.LIST_CUSTOMERS_IGNORED}>
            <MenuItemLink
              to="/customers-fraud-ignored"
              primaryText="Clientes ignorados"
              leftIcon={<Contacts />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      {/* <Permissions permissions={[
        PERMISSIONS.MANAGE_EXTERNAL_PAYMENT_METHOD,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuPayments')}
          isOpen={state.menuPayments}
          name="Pagamentos"
          dense={dense}
          icon={<Payment />}
        >
          <Permission permission={PERMISSIONS.DEFAULT_POINTS}>
            <MenuItemLink
              to="/chains/chainId/external-payment-methods"
              primaryText="Vínculo externos"
              leftIcon={<Payment />}
            />
          </Permission>
        </SubMenu>
      </Permissions> */}
      <Permissions permissions={[
        PERMISSIONS.SPECIAL_RULES,
        PERMISSIONS.DEFAULT_POINTS,
        PERMISSIONS.RULES_BY_GROUP,
        PERMISSIONS.ADD_POINTS,
        PERMISSIONS.PENDING_POINTS,
        PERMISSIONS.ADD_POINTS_BY_FUEL,
        PERMISSIONS.IMPORT_POINTS,
        PERMISSIONS.POINT_RULES,
      ]}>
        <SubMenu
          handleToggle={() => handleToggle('menuPoints')}
          isOpen={state.menuPoints}
          name="Pontuação"
          dense={dense}
          icon={<BallotIcon />}
        >
          <Permission permission={PERMISSIONS.DEFAULT_POINTS}>
            <MenuItemLink
              to="/default-points-edit"
              primaryText="Padrão"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.RULES_BY_GROUP}>
            <MenuItemLink
              to="/chains/chainId/group-rules"
              primaryText="Por Grupo"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.DEFAULT_POINTS}>
            <MenuItemLink
              to="/default-points-fuels-edit"
              primaryText="Reajuste"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.SPECIAL_RULES}>
            <MenuItemLink
              to="/special-rules"
              primaryText="Especiais"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.PENDING_POINTS}>
            <MenuItemLink
              to="/chains/chainId/pending-points"
              primaryText="Aprovar Pendências"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.ADD_POINTS}>
            <MenuItemLink
              to="/add-points"
              primaryText="Adição por Cupom"
              leftIcon={<AddBoxIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.ADD_POINTS_BY_FUEL}>
            <MenuItemLink
              to="/add-points-by-refuel"
              primaryText="Adição por Abastecimento"
              leftIcon={<LocalGasStationIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.MANAGE_COMPANY_WITHOUT_POINT}>
            <MenuItemLink
              to="/chains/chainId/companies-without-points"
              primaryText="CNPJs excluídos"
              leftIcon={<BallotIcon />}
            />
          </Permission>
          <Permission permission={PERMISSIONS.IMPORT_POINTS}>
            <MenuItemLink
              to="/import-points"
              primaryText="Importar"
              leftIcon={<ImportIcon />}
            />
          </Permission>
          <CheckChainUsesModule module={ModuleKey.LIMITS}>
            <Permission permission={PERMISSIONS.POINT_RULES}>
              <MenuItem style={{ display: 'list-item' }}>
                <SubMenu
                  handleToggle={() => handleToggle('menuPointRules')}
                  isOpen={state.menuPointRules}
                  name="Limites"
                  dense={dense}
                  icon={<BallotIcon />}
                >
                  <MenuItemLink
                    to="/chains/chainId/point-limit"
                    primaryText="Pontos"
                    leftIcon={<BallotIcon />}
                  />
                  <MenuItemLink
                    to="/chains/chainId/limit-number-fillin"
                    primaryText="Número de Abastecimentos"
                    leftIcon={<BallotIcon />}
                  />
                  <MenuItemLink
                    to="/chains/chainId/point-fillin-interval"
                    primaryText="Por Intervalo"
                    leftIcon={<BallotIcon />}
                  />
                </SubMenu>
              </MenuItem>
            </Permission>
          </CheckChainUsesModule>
        </SubMenu>
      </Permissions>
      <Permission permission={PERMISSIONS.UNREDEEM_COUPON}>
        <MenuItemLink
          to="/unredeem-coupon"
          primaryText="Desvalidar Cupom"
          leftIcon={<ConfirmationIcon />}
        />
      </Permission>
      <Permission permission={PERMISSIONS.BILLING_PAYMENT}>
        <MenuItemLink
          to="/billings-payment"
          primaryText="Contas a pagar"
          leftIcon={<MonetizationOnIcon />}
        />
      </Permission>
      <Permission permission={PERMISSIONS.APP_LABEL}>
        <MenuItemLink
          to="/app-labels"
          primaryText="Textos do aplicativo"
          leftIcon={<AppSettingsAltIcon />}
        />
      </Permission>
      <Permission permission={PERMISSIONS.SETTINGS}>
        <MenuItemLink
          to="/settings"
          primaryText="Configurações"
          leftIcon={<SettingsIcon />}
        />
      </Permission>
    </div >
  );
};

export default Menu;
