import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import {
	ArrayField,
	ChipField,
	Datagrid,
	DateField,
	FunctionField,
	NumberField,
	ReferenceArrayField,
	ReferenceArrayInput,
	ReferenceField,
	ReferenceInput,
	SelectArrayInput,
	SelectField,
	SelectInput,
	SingleFieldList,
	TextField,
	useListContext,
	useRecordContext
} from 'react-admin';
import { BetweenDatesTimesInput } from '../../components/BetweenDatesInput';
import Permission from '../../components/Permission';
import ReportList from '../../components/ReportList';
import { PERMISSIONS } from '../../constants';
import { getChainId, getPlaceId } from '../../lib';
import { CustomizableDatagrid } from '../../components';

const useStyles = makeStyles(theme => ({
	title: {
		margin: '10px 0 10px 0',
		textAlign: 'center',
		fontWeight: 'bold'
	},
}));

const discountChoices = [
	{ id: 'withDiscount', name: 'Com Desconto' },
	{ id: 'withoutDiscount', name: 'Sem Desconto' }
];

const statusChoices = [
	{ id: 'pendingReserve', name: 'Reserva Pendente' },
	{ id: 'reserved', name: 'Reservado' },
	{ id: 'pendingAuthorization', name: 'Autorização Pendente' },
	{ id: 'authorized', name: 'Autorizado' },
	{ id: 'waiting', name: 'Aguardando Abastecimento' },
	{ id: 'delivering', name: 'Abastecendo' },
	{ id: 'deliveringWithoutFollowUp', name: "Abastecendo sem acompanhamento" },
	{ id: 'error', name: 'Erro' },
];

const ValueField = () => {
	const record = useRecordContext();
	const source = record.value ? "value" : "fillInInfo.value";

	return (<NumberField
		source={source}
		label="Valor"
		textAlign="center"
		emptyText="R$ 0,000"
		options={{
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}}
	/>);
}

const VolumeField = () => {
	const record = useRecordContext();
	const source = record.volume ? "volume" : "fillInInfo.volume";

	return (<NumberField
		source={source}
		label="Litros"
		textAlign="center"
		emptyText="0"
		options={{
			maximumFractionDigits: 2
		}}
	/>);
}

const PriceField = () => {
	const record = useRecordContext();
	const source = record.price ? "price" : "fillInInfo.price";

	return (<NumberField
		source={source}
		label="Preço"
		textAlign="center"
		emptyText="R$ 0,000"
		options={{
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 3,
			maximumFractionDigits: 3
		}}
	/>);
}

const FuelField = () => {
	const record = useRecordContext();
	if (!record) return <></>;

	const source = record.fuel && record.fuel.id ? "fuel.id" : "stationFuel.fuelId";

	return (
		<ReferenceField label="Combustível" emptyText="Não identificado" source={source} reference="fuels" basePath="fuels" link="show">
			<TextField source="name" label="Combustível" />
		</ReferenceField>
	);
}

const slowReserve = (record) => {
	return {
		backgroundColor: !record.reservedAt ? '#fae38e' : ((new Date(record.reservedAt) - new Date(record.startedAt)) / 1000) > 10 ? '#fa987f' :
			(record.updatedAt && ((new Date() - new Date(record.updatedAt)) / 1000) > 180) ? '#fae38e' : '#ffffff'
	}
};

const FillinsList = () => {
	const classes = useStyles();
	const props = useListContext();

	let data = [];
	let summaryData = [];

	if (props.data) {
		const fillinInfo = props.data[0];

		if (fillinInfo) {
			data = fillinInfo.data;
			summaryData = fillinInfo.summaryData;
		}
	}

	return (
		<>
			<Typography className={classes.title}>Total</Typography>
			<Datagrid bulkActionButtons={false} style={{ marginBottom: 60 }} data={summaryData}>
				<SelectField source="id" choices={statusChoices} emptyText='Todos' label="Status" />
				<NumberField
					source="count"
					label="Total"
					textAlign="center"
				/>
			</Datagrid>

			<Typography className={classes.title}>Abastecimentos em Andamento</Typography>
			<CustomizableDatagrid
				rowStyle={slowReserve}
				resource={props.resource}
				bulkActionButtons={false}
				data={data}
				defaultColumns={['stationId', 'chainId', 'status', 'reserveId', 'value', 'amount', 'fuel_id', 'startedAt']}
			>
				<ReferenceField
					label="Rede"
					emptyText="Rede não identificada"
					source="chainId"
					reference="chains"
					basePath="chains"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Posto"
					emptyText="Posto não identificado"
					source="stationId"
					reference="stations"
					basePath="stations"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<ReferenceField
					label="Frentista"
					emptyText="Não identificado"
					source="attendant.id"
					reference="attendants"
					basePath="attendants"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<TextField source="reserveId" label="Reserva" />
				<ReferenceField
					label="Cliente"
					emptyText="Não identificado"
					source="customerId"
					reference="customers"
					basePath="customers"
					link="show"
				>
					<TextField source="name" />
				</ReferenceField>
				<SelectField
					emptyText="Não identificado"
					source="status"
					choices={statusChoices}
				/>
				<ValueField label="Valor" />
				<VolumeField label="Volume" />
				<PriceField label="Preço" />
				<FuelField label="Combustível" />
				<DateField source="startedAt" label="Criado em" showTime />
				<FunctionField source="reservedAt" label="Reservado em (segundos)" render={record => record.reservedAt ? ((new Date(record.reservedAt) - new Date(record.startedAt)) / 1000).toFixed(1) : '-'} />
				<DateField source="reservedAt" label="Reservado em" showTime />
				<DateField source="updatedAt" label="Atualizado em" showTime />
			</CustomizableDatagrid>
		</>
	);
}

const fillinsExporter = async (data) => {
	return data[0].data.map(fillin => (
		{
			status: fillin.status,
			reserveId: fillin.reserveId,
			value: fillin.value || (fillin.fillInInfo && fillin.fillInInfo.value),
			volume: fillin.volume || (fillin.fillInInfo && fillin.fillInInfo.volume),
			startedAt: fillin.startedAt,
		}
	));
}

const HoseNumberChip = (props) => {
	const record = useRecordContext();
	if (!record) { return null; }
	return (
		<ChipField record={{ hoseNumber: record }} source="hoseNumber" />
	);
}

const FilterTablePrint = () => {
	const classes = useStyles();
	const { filterValues } = useListContext();
	return (
		<Datagrid bulkActionButtons={false} data={[{ ...filterValues, generatedReportAt: moment().toISOString() }]} total={1}>
			<DateField source="from" textAlign="center" label="De" sortable={false} />
			<DateField source="to" textAlign="center" label="Até" sortable={false} />
			<ReferenceArrayField label="Posto" source="stationIds" textAlign="center" basePath="stations" reference="stations" link={false} >
				<Datagrid bulkActionButtons={false}>
					<TextField source="name" label={"Postos"} />
				</Datagrid>
			</ReferenceArrayField>
			<ReferenceField label="Combustível" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
				<TextField source="name" />
			</ReferenceField>
			<ReferenceArrayField label="Grupo de clientes" emptyText="Todos" source="customerGroupIds" textAlign="center" basePath="chains/chainId/customer-groups" reference="chains/chainId/customer-groups" link={false}>
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>
			<ArrayField label="Bicos" emptyText="Todos" source="hoseNumbers" textAlign="center">
				<SingleFieldList>
					<HoseNumberChip />
				</SingleFieldList>
			</ArrayField>
			<DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} />
		</Datagrid>
	);
}

const ReportCurrentFillins = (props) => {
	const listFilters = [
		<BetweenDatesTimesInput alwaysOn />,
		<ReferenceArrayInput
			source="stationIds"
			reference="stations"
			perPage={null}
			sort={{ field: "name", order: "ASC" }}
			alwaysOn
		>
			<SelectArrayInput
				emptyText="Todos"
				label="Posto"
				style={{ minWidth: 180 }}
				optionText={'name'}
			/>
		</ReferenceArrayInput>,
		<ReferenceArrayInput
			source="fuelIds"
			reference="fuels"
			sort={{ field: "name", order: "ASC" }}
			alwaysOn
		>
			<SelectArrayInput
				label="Combustível"
				emptyText="Todos"
				style={{ minWidth: 180 }}
				optionText={'name'}
			/>
		</ReferenceArrayInput>,
		<SelectInput label="Desconto" choices={discountChoices} emptyText={"Todos"} source="discount" alwaysOn />,
		<SelectInput label="Status" choices={statusChoices} emptyText={"Todos"} source="status" alwaysOn />,
	];

	const stationsIndex = listFilters.findIndex(({ props }) => props.reference === 'stations')
	const placeId = getPlaceId();

	if (placeId && stationsIndex > -1) {
		listFilters.splice(stationsIndex, 1)
	}

	if (stationsIndex > -1) {
		listFilters.splice(stationsIndex, 0,
			<ReferenceInput
				source="chainId"
				reference="chains"
				perPage={null}
				sort={{ field: "name", order: "ASC" }}
				alwaysOn
			>
				<SelectInput
					emptyText="Todos"
					label="Rede"
					style={{ minWidth: 180 }}
					optionText={'name'}
				/>
			</ReferenceInput>)
	}

	return (
		<Permission permission={PERMISSIONS.ADMIN}>
			<ReportList
				{...props}
				basePath="fillins"
				title="Abastecimento"
				resource="reports/current-fillins"
				filters={listFilters}
				sort={{ field: 'created_at', order: 'DESC' }}
				filterDefaultValues={{
					stationIds: placeId ? [placeId] : null,
					chainId: getChainId(),
					from: moment().add(-2, 'hours').toISOString(),
					to: moment().endOf('day').toISOString()
				}}
				showPagination={false}
				bulkActionButtons={false}
				customExporter={fillinsExporter}
				titleOnPrint="Relatório de abastecimento em andamento"
				filterTablePrint={<FilterTablePrint />}
				landscape
			>
				<FillinsList />
			</ReportList>
		</Permission >
	);
};

export default ReportCurrentFillins;